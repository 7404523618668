// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-biologique-js": () => import("./../../../src/pages/biologique.js" /* webpackChunkName: "component---src-pages-biologique-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modal-example-js": () => import("./../../../src/pages/modal-example.js" /* webpackChunkName: "component---src-pages-modal-example-js" */),
  "component---src-pages-varietes-pommes-js": () => import("./../../../src/pages/varietes-pommes.js" /* webpackChunkName: "component---src-pages-varietes-pommes-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-histoire-js": () => import("./../../../src/templates/histoire.js" /* webpackChunkName: "component---src-templates-histoire-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-verger-js": () => import("./../../../src/templates/verger.js" /* webpackChunkName: "component---src-templates-verger-js" */)
}

