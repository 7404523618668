export const LINKS = {
  "fr": [
    {
      url: "/",
      name: "Accueil"
    },
    {
      url: "/services/",
      name: "Services"
    },
    {
      url: "/pommes/",
      name: "Nos pommes"
    },
    {
      url: "/histoire/",
      name: "Histoire"
    },
    {
      url: "/blog/",
      name: "Le blog du verger"
    },
    {
      url: "/faq/",
      name: "FAQ"
    }
  ], "en": [
    {
      url: "/",
      name: "Home"
    },
    {
      url: "/services/",
      name: "Services"
    },
    {
      url: "/apples/",
      name: "Our apples"
    },
    {
      url: "/story/",
      name: "Our story"
    },
    {
      url: "/faq/",
      name: "FAQ"
    }
  ]
}