import React, { useRef } from 'react'
import './base.css'
import { ParallaxProvider } from 'react-scroll-parallax'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAppleAlt, faBars, faBox, faCarrot, faCheckDouble, faCreditCard, faDog, faDoorOpen, faExternalLinkAlt, faHome, faHourglass, faPlay, faShoppingBag, faShoppingCart, faShuttleVan, faTimes, faWeight } from '@fortawesome/free-solid-svg-icons'
import Transition from './transition'
import Footer from "./footer"
import Menu from "./menu"

import { useTransition, animated } from 'react-spring'


library.add(faAppleAlt, faBars, faBox, faCarrot, faCheckDouble, faCreditCard, faDog, faDoorOpen, faExternalLinkAlt, faHome, faHourglass, faPlay, faShoppingBag, faShoppingCart, faShuttleVan, faTimes, faWeight)


function keyExistsInArray(key, arr) {
  return arr.some(function (el) {
    return el.key === key
  })
}


const Template = ({ location, children }) => {
  const visitedRoutes = useRef([])

  const transitions = useTransition(location, location.pathname, {
    from: { position: 'absolute', width: '100%', opacity: 0.01, transform: 'translate3d(10%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0%,0,0)' },
    unique: true,
    reset: true,
  })

  const exists = keyExistsInArray(children.key, visitedRoutes.current)
  if (!exists) {
    visitedRoutes.current.push(children)
  }

  return (
    <>
      <Menu location={location.pathname} />

      <ParallaxProvider>
        {transitions.map(({ item, props, key }) => {
          return (
              <animated.div
                key={key}
                style={props}
              >
                {item.pathname === children.key ? (
                  // entering view
                  children
                ) : (
                  // again, not sure if this is the best approach
                  // exiting view. or just render children if its the initial render
                  visitedRoutes.current.find(x => x.key === key) || children
                )}
                <Footer></Footer>
              </animated.div>
          )
        })}
      </ParallaxProvider>
    </>
  )
}


export default Template
