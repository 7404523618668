import React, { useState, useCallback } from 'react'
import { Transition, animated } from 'react-spring'
import {TransitionGroup, Transition as ReactTransition} from "react-transition-group"

const timeout = 200
const getTransitionStyles = {
    entering: {
        position: `absolute`,
        opacity: 0,
        transform: `translate3d(100%,0,0)`
    },
    entered: {
        transition: `all ${timeout}ms ease-in-out`,
        opacity: 1,
        transform: `translate3d(0%,0,0)`
    },
    exiting: {
        transition: `all ${timeout}ms ease-in-out`,
        opacity: 0,
        transform: `translate3d(-50%,0,0)`
    }
}

// from={{ opacity: 0, transform: 'translate3d(100%,0,0)' }}
//                     enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
//                     leave={{ opacity: 0, transform: 'translate3d(-50%,0,0)' }}>

class TransitionComponent extends React.Component {
    render() {
        const {children, location} = this.props
        var key = "index";
        if (location && location.pathname) {
            key = location.pathname
        }
        return (
            <TransitionGroup>
                <ReactTransition
                    key={key}
                    timeout={{
                        enter: timeout,
                        exit: timeout
                    }}>
                    {status => (
                        <div
                            style={{
                                ...getTransitionStyles[status],
                            }}>
                            {children}
                        </div>
                    )}    
                </ReactTransition>
            </TransitionGroup>
        )
    }
}

// export default class TransitionComponent extends React.PureComponent {
//     state = { index: 0 }
//     toggle = e =>
//         this.setState(state => ({
//         index: state.index === 2 ? 0 : state.index + 1,
//         }))

//     render() {
//         const {children, location} = this.props
//         var key = "index";
//         if (location && location.pathname) {
//             key = location.pathname
//         }

//         return (
//             <div>
//                 <Transition
//                     native
//                     reset
//                     unique
//                     items={this.state.index}
//                     from={{ opacity: 0, transform: 'translate3d(100%,0,0)' }}
//                     enter={{ opacity: 1, transform: 'translate3d(0%,0,0)' }}
//                     leave={{ opacity: 0, transform: 'translate3d(-50%,0,0)' }}>
//                         <animated.div>{children}</animated.div>
//                 </Transition>
//             </div>
//         )
//     }
// }

export default TransitionComponent