import React, { useState, useCallback } from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Img from "../components/image"
import styles from './menu.module.css'
import { Spring, Transition } from 'react-spring/renderprops'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Motion, spring} from 'react-motion';
import {LINKS} from '../components/constants';

import { Slug, Fade } from './primitives';

var $ = require( "jquery" );

class Menu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      cellClassnames: "cell",
      bgRefs: [],
      currentBgColor: "dark",
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    this.updateBgRefs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      setTimeout(() => this.updateBgRefs(), 1000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  updateBgRefs = () => {
    let bgRefs = [];
    $(".bg-ref-light").each(function(index) {
      const pos = $(this).offset().top;
      bgRefs.push({
        color: 'light',
        pos
      });
    });

    $(".bg-ref-dark").each(function(index) {
      const pos = $(this).offset().top;
      bgRefs.push({
        color: 'dark',
        pos
      });
    });

    bgRefs.sort((a, b) => a.pos - b.pos);

    this.setState({bgRefs});
    this.handleScroll();
  }

  handleScroll = () => {
    const { bgRefs, currentBgColor } = this.state;
    const curPos = $(window).scrollTop() + 70;

    let newColor = null;
    for (let i=0; i<bgRefs.length; i++) {
      const bgRef = bgRefs[i];
      if (i === (bgRefs.length - 1)) {
        newColor = bgRef.color;
      } else if (curPos >= bgRef.pos && curPos < bgRefs[i + 1].pos) {
        newColor = bgRef.color;
        break;
      }
    }

    if (newColor && newColor !== currentBgColor) {
      this.setState({
        currentBgColor: newColor
      });
    }
  }

  toggleShow = () => {
    const newShowValue = !this.state.show;

    this.setState({
      show: newShowValue
    });

    if (!newShowValue) {
      this.setState({
        cellClassnames: "cell cell-full"
      });
    } else {
      setTimeout(() => {
        this.setState({
          cellClassnames: "cell"
        });
      }, 1000);
    }
  }

  render() {
    const { show, cellClassnames, currentBgColor } = this.state;

    const active = !show;

    return (
      <div>
        <div className={"menu-container " + (currentBgColor === "dark" ? "menu-light" : "menu-dark")}>
          {(this.props.location !== "/" && this.props.location !== "/fr/") && <Link to={"/"}>
            <div className={"menu-home-btn " + (active ? "menu-btn-close" : "")}>
              <FontAwesomeIcon icon="home" className="menu-home-btn-icon"/>
            </div>
          </Link>}

          {/* <a href="https://boutique.vergerjoannette.ca">
            <div className={"menu-cart-btn " + (active ? "menu-btn-close" : "")}>
              <FontAwesomeIcon icon="shopping-cart" className="menu-cart-btn-icon"/>
            </div>
          </a> */}

          <div className={"menu-btn " + (active ? "menu-btn-close" : "")} onClick={this.toggleShow}>
            <FontAwesomeIcon icon={active ? "times" : "bars"} className="menu-btn-icon"/>
          </div>
        </div>

        <div
          className={cellClassnames}>
          <Fade show={active} delay={active ? 50 : 0}>
            <div className="details">
              {LINKS[process.env.GATSBY_LANG].map((link, i) => (
                <Slug delay={i*50} key={"menu-slug-" + i}>
                  <Link to={link.url} className={styles.menuItem} onClick={this.toggleShow}>{link.name}</Link>
                </Slug>
              ))}
              {/* <Slug delay={350}>
                <a href="https://boutique.vergerjoannette.ca" className={styles.menuItem}><b><FontAwesomeIcon icon="shopping-cart" /> Accéder à la boutique</b></a>
              </Slug> */}
              <Slug delay={100}>
                <a href={`https://vergerjoannette.ca/${process.env.GATSBY_LANG === "fr" ? "en" : "fr"}/`} className={styles.menuLang}>{process.env.GATSBY_LANG === "fr" ? "English" : "Français"}</a>
              </Slug>
            </div>
          </Fade>
        </div>

      </div>
    )
  }
}

export default Menu